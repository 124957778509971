<template>
  <div>
    <vs-input
      v-model="email"
      v-validate="'required|email|min:3'"
      class="w-full"
      data-vv-validate-on="blur"
      icon="icon icon-user"
      icon-no-border
      icon-pack="feather"
      label-placeholder="Email" name="email"
      @keyup.enter="loginJWT"/>
    <span class="text-danger text-sm">{{ errors.first('email') }}</span>

    <vs-input
      v-model="password"
      v-validate="'required|min:6'"
      class="w-full mt-6"
      data-vv-validate-on="blur"
      icon="icon icon-lock"
      icon-no-border
      icon-pack="feather"
      label-placeholder="Password"
      name="password" type="password"
      @keyup.enter="loginJWT"/>
    <span class="text-danger text-sm">{{ errors.first('password') }}</span>

    <vs-input
      v-if="showTfa"
      v-validate="'min:6|max:6'"
      v-model="tfa"
      class="w-full mt-6"
      data-vv-validate-on="blur"
      icon="icon icon-lock"
      icon-no-border
      icon-pack="feather"
      label-placeholder="Multi-Factor Auth Code"
      name="tfa" type="tfa"
      @keyup.enter="loginJWT"/>
    <span class="text-danger text-sm">{{ errors.first('tfa') }}</span>

    <div class="flex flex-wrap justify-between my-5">
      <router-link to="/forgot-password">Forgot Password?</router-link>
    </div>
    <div class="flex flex-wrap justify-between mb-3">
      <vs-button type="border" @click="registerUser">Register</vs-button>
      <vs-button :disabled="!validateForm || disable" @click="loginJWT">Login</vs-button>
    </div>
    <!--    <vs-divider>or</vs-divider>-->
    <!--    <div class="flex flex-wrap justify-between mb-3">-->
    <!--      <vs-button @click="loginGoogle">Sign-in with Google</vs-button>-->
    <!--      <vs-button disabled>Sign-in with Github</vs-button>-->
    <!--    </div>-->
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: '',
      password: '',
      tfa: '',
      disable: false,
      showTfa: false,
    };
  },
  computed: {
    validateForm() {
      return !this.errors.any() && this.email !== '' && this.password !== '';
    },
  },
  methods: {
    async loginGoogle(googleUser) {
      googleUser = await this.$gAuth.signIn();

      const profile = googleUser.getBasicProfile();

      let payload = {
        platform: 'google',
        profile: {
          id: profile.getId(),
          first_name: profile.getGivenName(),
          last_name: profile.getFamilyName(),
          email: profile.getEmail(),
        },
        auth: googleUser.getAuthResponse(),
      };

      this.$vs.loading();
      this.$http.post(`auth/login/3rd/google`, payload)
        .then(response => {

          const data = response.data.data;

          // Set accessToken
          localStorage.setItem('accessToken', data.tokens.access_token);
          localStorage.setItem('refreshToken', data.tokens.refresh_token);

          if (data.user.first_name) {
            data.user.full_name = `${data.user.first_name} ${data.user.last_name}`;
          }

          // Update user details
          this.$store.commit('UPDATE_USER_INFO', data.user, {root: true});

          this.$vs.loading.close();
          location.reload();
        }).catch(e => {

        this.$vs.notify({
          title: 'Failed to login',
          text: 'An error occurred while attempting to signin. Refresh and try again',
          color: 'danger',
        });
        this.$vs.loading.close();
      });

    },
    checkLogin() {
      // If user is already logged in notify
      if (this.$store.state.auth.isUserLoggedIn()) {

        this.$vs.notify({
          title: 'Login Attempt',
          text: 'You are already logged in!',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
        });

        return false;
      }
      return true;
    },
    async loginJWT() {

      if (!this.checkLogin()) return;

      // Loading
      this.$vs.loading();
      this.disabled = true;

      const payload = {
        email: this.email,
        password: this.password,
        tfa: this.tfa,
        recaptcha: await this.$recaptcha('login'),
      };

      this.$store.dispatch('auth/loginJWT', payload)
        .then(() => {

          this.$gtag.event('auth_login_success', {
            event_category: 'authentication',
            event_label: 'auth_login',
          });

          this.$vs.loading.close();
          location.reload();
        })
        .catch(error => {
          this.$vs.loading.close();
          this.disabled = false;

          if (error.response) {

            const response = error.response;

            if (response.status === 400 || response.status === 403) {
              this.$gtag.event('auth_login_failure', {
                event_category: 'authentication',
                event_label: 'auth_failure',
              });
              const desc = response.data.error.description;

              if (desc === 'No two-factor-auth code was provided') {
                this.showTfa = true;
                // Transition to TFA screen
              } else if (desc === 'Invalid two-factor-auth token') {
                this.showTfa = true;
                // Invalid TFA code provided
              } else if (desc === 'The password provided is not valid') {
                // The password provided is not valid
              } else if (desc === 'Your account is currently suspended.') {
                // Your account is currently suspended
              } else if (desc === 'Account\'s email has not been confirmed') {
                // Account's email has not been confirmed
              } else if (desc === 'No account exists with the provided email') {
                // No account exists with the provided email
              } else if (desc === 'Invalid recaptcha solution') {
                // No account exists with the provided email
              } else {

              }

              return this.$vs.notify({
                title: 'Failed to login',
                text: desc,
                iconPack: 'feather',
                icon: 'icon-user-x',
                color: 'danger',
              });
            }

            this.$vs.notify({
              title: 'Error',
              text: error.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger',
            });

          }

        });
    },
    registerUser() {
      this.$router.push('/register');
    },
  },
  mounted() {

  },
};

</script>

<style>

.vs-input--input {
  border: 1px solid #fff !important;
  border-radius: 5px;
}

</style>
